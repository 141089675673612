import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FooterBox from '../components/share/footerBox'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "designContent/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgroundBooklets: file(
      relativePath: { eq: "designContent/background-booklets.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgroundVideo: file(
      relativePath: { eq: "designContent/background-video.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgroundLJHooker: file(
      relativePath: { eq: "designContent/background-ljhooker.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pencil: file(relativePath: { eq: "designContent/pencil.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    invoiceTotal: file(relativePath: { eq: "designContent/invoiceTotal.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    documents: file(relativePath: { eq: "designContent/documents.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    trifolds: file(relativePath: { eq: "designContent/trifolds.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    newspaper: file(relativePath: { eq: "designContent/newspaper.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    isometric: file(relativePath: { eq: "designContent/isometric.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    socialtiles: file(relativePath: { eq: "designContent/socialtiles.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    harristiles: file(relativePath: { eq: "designContent/harristiles.png" }) {
      childImageSharp {
        fluid(maxWidth: 980) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class DesignContentPage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                style={{ height: '600px' }}
                opacity="0.5"
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'palette']}
                    style={{ width: '25px', height: '25px', marginBottom: 10 }}
                  />

                  <HeaderTitle>
                    Creative design and brand management
                  </HeaderTitle>

                  <HeaderBody>
                    A real estate focused creative agency that truly understands
                    the limitations, specifications and requirements an agency
                    has in generating leads, selling property and promoting
                    consistent branding.
                  </HeaderBody>
                  <TypeFormButton text="MORE INFO" />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper>
                <BannerH4>
                  It’s not enough to just <strong>design an artwork </strong>{' '}
                  your designer needs to understand{' '}
                  <strong>specific rules</strong>, that marketing for property
                  requires.
                </BannerH4>
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.documents.childImageSharp.fluid}
                  title="Pre-lists, Proposals, Market Strategies, 16 page property booklets."
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        There’s not really a limit to what we see daily required
                        by offices. Some people prefer to keep their marketing
                        short and sweet, some people require the equivalent of
                        entire magazine publications worth of content and layout
                        design completed.
                      </Paragraph>
                      <Paragraph>
                        We’ve completed hundreds of agency pre-lists,
                        promotional booklets, proposal documents, development
                        brochures and designs from a single page A4 up to 24
                        page prestige property brochures.
                      </Paragraph>
                      <Paragraph>
                        You need a team that understands real estate, and it’s
                        requirements to pull off the level of content that will
                        elevate you above the competition.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ImageBackground
                height="300px"
                opacity="0.5"
                background={data.backgroundBooklets.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    descWidth="50%"
                    title="Any document, any brand message from single artwork to complete marketing library."
                  />
                </ContentWrapper>

                <NewsImage
                  fluid={data.newspaper.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>

              <ContentWrapper style={{ margin: '150px auto' }}>
                <IntroRow
                  reverse
                  descWidth="50%"
                  image={data.trifolds.childImageSharp.fluid}
                  title="Create beautiful, impactful and informative market content that generates leads."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          You’re the expert in your local market. Use our
                          services to turn your ideas and experience into
                          beautiful, brand specific content that attracts
                          customers.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Marketing is in our blood. Quality marketing is where we
                        shine. Our pedigree of effective print and digital
                        marketing has helped local agencies build huge customer
                        bases off the back of creating unique, informative print
                        and digital campaigns that establish your authority in
                        your local markets.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                height="500px"
                opacity="0.7"
                background={data.backgroundVideo.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    descWidth="50%"
                    title="The importance of video content can’t be overstated."
                    content={
                      <Fragment>
                        <Paragraph>
                          Campaigns that combine print, digital and video have
                          the most impact across any marketing you could ever
                          offer. Our marketing abilities extend from complete
                          conceptual creation to the exceptional shooting,
                          editing and delivering of video content that perfectly
                          complements a brand message alongside all your other
                          efforts.
                        </Paragraph>

                        <Paragraph>
                          The more relevant touch points a user has to the same
                          contact, increases it’s effectiveness ten-fold.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  title="Co-ordinate with our talented design teams to create an identifiable and cohesive brand."
                  image={data.isometric.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Customer’s ‘marketing’ brain will switch off from your
                          messaging if you don’t make enough of an impact.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        Disjointed marketing confuses customers. Stale marketing
                        alienates customers. Keeping up to date with e latest
                        styles, and presenting every peice of marketing that
                        immediately demonstrates your marketing ability for
                        their property will elevate your brand in the eyes of
                        your customers.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  reverse
                  descWidth="50%"
                  title="Brand messaging that doesn’t leave social media as an afterthought."
                  image={data.socialtiles.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Every touch point, every first impression has the
                          potential to grow your business.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        There’s no limitation to the level of impact you can
                        make by presenting a unified and consistent brand in the
                        marketplace. Our specialists know how to extract your
                        brand and personality and make it fit into the most
                        impactful content deliver, required to compete in the
                        current market.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                height="700px"
                opacity="0.7"
                background={data.backgroundLJHooker.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    descWidth="50%"
                    title="Don’t replace your existing brand designers, compliment them with Real Estate focused expertise."
                    content={
                      <Fragment>
                        <Paragraph>
                          Design as a career and the experience of career
                          designers are invaluable when creating content that
                          looks good. But looking good and functioning within
                          the context of real estate requirements is another
                          entire area of knowledge that most designers don’t
                          have the experience to undertake.
                        </Paragraph>

                        <Paragraph>
                          Working with thousands of agents, across tens of
                          thousands of marketing campaigns, and consulting with
                          brand groups on the entire overhaul of a real estate
                          brand has left us with unmatched experience that saves
                          you time, hassle and upset franchisees.
                        </Paragraph>
                        <Paragraph>
                          We know the specifications and requirements that are
                          most commonly used in marketing materials like the
                          back of our hands.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  title="Assets and branding, built for everywhere an agent needs."
                  content={
                    <strong>
                      Our understanding of the multidude of requirements a brand
                      has, to keep consistency across entire groups is second to
                      none.
                    </strong>
                  }
                />
                <Img
                  fluid={data.harristiles.childImageSharp.fluid}
                  style={{ width: '100%', height: '100%' }}
                />
              </ContentWrapper>
              <ContentWrapper>
                <BannerH4>
                  Let our experience designing purely for{' '}
                  <strong>the real estate niche</strong> either lead, or guide
                  your <strong>brand presence</strong>, and avoid costly
                  unforeseen pitfalls.
                </BannerH4>
              </ContentWrapper>

              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Get <strong>every marketing product</strong> in the{' '}
                  <strong>one place</strong>, and <strong>save hours</strong>{' '}
                  communicating and tracking entire campaigns.
                </BannerH4>

                <TypeFormButton />
              </ContentWrapper>
              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>
              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default DesignContentPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const NewsImage = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 250px;
  height: 250px;
  bottom: -90px;
  right: 20px;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 250px;
    bottom: -105px;
    right: 80px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 350px;
    height: 350px;
    bottom: -150px;
    right: -0px;
  `};
`
